import { MenuData } from '@/types/api';

export enum ViewType {
  None,
  Modal,
  Blank,
}

export enum SiteHeader {
  OurSites = 'OurSites',
  ForWork = 'ForWork',
  BugReport = 'BugReport',
  FnfNews = 'FnfNews',
  Approval = 'Approval',
}

export const bugReportLoadMaps: MenuData[] = [
  {
    name: '오류 불편 신고',
    link: 'https://fnf.atlassian.net/servicedesk/customer/portal/50/group/154/create/813',
    action: 'newTab',
    isDisplay: true,
    childData: [],
  },
];
